import { useEffect, useState } from "react";
import Home from "./Home/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./About";
import Service from "./Service";
import Blog from "./Blog";
import Contact from "./Contact";
import MainService from "./MainService";
import OurPackages from "./OurPackages";
import ScrollToTop from "./scrollToTop";
import BlogDetails from "./BlogDetails";
import Continental from "./Continental";
import Asia from "./International/Asia";

import Visaa from "./Visaa";
import VisaPackagee from "./VisaPackagee";
import India from "./India";

import MiddleEast from "./International/MiddleEast";
import America from "./International/America";

import Pacific from "./International/Pacific";
import Africa from "./International/Africa";
import Europe from "./International/Europe";
import Trend from "./Home/Trend";
import Detailspackage from "./Detailspackage";
import PrivacyPolicy from "./Footermainpage/PrivacyPolicy";
import Termspage from "./Footermainpage/Termspage";
import Delivery from "./Footermainpage/Delivery";
import Refund from "./Footermainpage/Refund";
import Quality from "./Footermainpage/Quality";
import Salem from "./Salem";

import Chennai from "./chennai";
import OurCompany from "./ourcompany";


function App() {
  const [ourpackage, setOurpackage] = useState("");

  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />


        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/travel-agency" element={<Contact />} />
        <Route path="/service/:serv" element={<MainService />} />
        <Route path="/ourpackage/:id" element={<OurPackages />} />
        <Route path="/package" element={<OurPackages />} />
        <Route path="/blog_details/:id/:name" element={<BlogDetails />} />
        <Route path="/international-tour-packages" element={<Continental />} />
        <Route path="/asia" element={<Asia />} />
        <Route path="/domestic-tour-packages" element={<India />} />
        <Route path="/visa" element={<Visaa />} />
        <Route path="/visa_package/:id" element={<VisaPackagee />} />
        <Route path="/middleeast" element={<MiddleEast />} />
        <Route path="/america" element={<America />} />
        <Route path="/Pacific" element={<Pacific />} />
        <Route path="/Africa" element={<Africa />} />
        <Route path="/Europe" element={<Europe />} />
        <Route path="/package-details/:id/:name" element={<Detailspackage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termspage" element={<Termspage />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/quality" element={<Quality />} />
        <Route path="/salem" element={<Salem />} />
        <Route path="/chennai" element={<Chennai />} />
        <Route path="/ourcompany" element={<OurCompany />} />
      </Routes>
    </div>
  );
}

export default App;
