import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "./common/Footer";
import Header from "./common/Header";
import config from "./config";
import { Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const VisaPackage = () => {
  const { id } = useParams();
  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(config.apiUrl + "visapackage.php");
        setData1(res.data.vp);
        setData(res.data.v);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filterVisa = data1.filter((data) => data.country == id);
  const filterVisa1 = data.filter((data) => data.id == id);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  

  const handleInput = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function alertBox() {
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  function msgBox() {
    if (showMsg) {
      return (
        <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }
  const handlePhone = (value, data, event) => {
    setValues((prev) => ({
      ...prev,
      ph: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(config.apiUrl + "visapackagemail.php", values)
        .then((res) => {
          if (res.data.success) {
            document.querySelector(".form").reset();
            setErrors(res.data.success);
            setShowMsg(true);
            setValues({});
          } else {
            setErrors("Please Try again Later");
            setShow(true);
          }
        })
        .catch((err) => {
          setErrors("Something Wrong Please Try again Later");
          setShow(true);
        });
    } catch {
      console.log("Error");
    }
  };
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;

  // const totalItems = filter.length;
  // const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <Header />
      {/* Banner Section */}
      <section className="cont-banner">
        <div className="banner-box">
          <h2 className="main-heading">{(filterVisa1[0]?.country ?? "").toUpperCase()} VISA</h2>
          <p className="mini-heading">
            Price Starting From ₹{" "}
            {filterVisa1.length > 0 &&
              filterVisa1[0].starting_price &&
              filterVisa1[0].starting_price}
          </p>
        </div>
      </section>
      {}
      {/* Package Section */}
      {!loading && (
        <div className="pd-main-sec position-relative mt-5">
          {filterVisa.length > 0 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <section className="package-sec">
                      <div className="container-xxl">
                        <div className="row pack-row">
                          {filterVisa.map((data) => (
                            <div className="col-md-6">
                              <div className="visa-box mb-4">
                                <div className="visa-item-box">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="visabx">
                                        {/* {data1.length > 0 && ( */}
                                        <div className="visa-con-bx">
                                          <div className="row">
                                            <div className="col-12 Heading-box-visa">
                                              <div className="main-head main-headd">
                                                <div className="visa-head-height">
                                                  <h5 className="visa-head-color">
                                                    {data.name}
                                                  </h5>
                                                </div>
                                              </div>
                                              <br />
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">
                                                      Process Time
                                                    </th>
                                                    <td>{data.process_time}</td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <th scope="row">
                                                      Stay Period
                                                    </th>
                                                    <td>{data.stay_period}</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">
                                                      Validity
                                                    </th>
                                                    <td>{data.validity}</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Type</th>
                                                    <td>{data.type}</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Entry</th>
                                                    <td>{data.entry}</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Fees</th>
                                                    <td className="fee-highlight">
                                                      INR {data.fees}/-
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        {/* )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-8">
                        {/* <!-- Embassy  --> */}
                        <p className="sub-heading">Embassy</p>
                        {/* <?php echo $apackage['embassy'] ?> */}
                        {filterVisa1.length > 0 && filterVisa1[0].embassy && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: filterVisa1[0].embassy,
                            }}
                          ></div>
                        )}
                      </div>
                      <br></br>
                      {/* <!-- Documents  --> */}
                      <div className="container">
                        <p className="sub-heading">
                          Documents for &nbsp;
                          {filterVisa1.length > 0 &&
                            filterVisa1[0].country &&
                            filterVisa1[0].country}
                        </p>

                        {/* <div dangerouslySetInnerHTML={{__html:filterVisa1[0].documents}}>
          </div> */}
                        {filterVisa1.length > 0 && filterVisa1[0].documents && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: filterVisa1[0].documents,
                            }}
                          ></div>
                        )}
                      </div>
                    </section>
                  </div>

                  <div className="col-lg-3 price-col stickycls">
                    <div className="price-box position-sticky">
                      <div className="form-boxxx">
                        {alertBox()}
                        {msgBox()}
                        <p className="mini-heading text-center">
                          Explore the World with Us
                        </p>
                        <form
                          className="form"
                          onSubmit={handleSubmit}
                          id="myform"
                        >
                          <input
                            className="w-100"
                            name="name"
                            type="text"
                            placeholder="Type your Name"
                            onChange={handleInput}
                            required
                          />
                          <PhoneInput
                            className="phone-us "
                            country={"in"}
                            onChange={handlePhone}
                            required
                          />
                          <input
                            className="w-100"
                            name="email"
                            type="email"
                            placeholder="Insert your Email"
                            onChange={handleInput}
                            required
                          />

                          <textarea
                            className="w-100"
                            name="message"
                            rows="2"
                            placeholder="Your Message"
                            onChange={handleInput}
                            required
                          ></textarea>
                          <div className="d-grid gap-2">
                            <button type="submit" className="btns cont-btn">
                              Send
                            </button>
                            <br />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 class="text-center text-warning mt-2 mb-4">No VISA found</h3>
            </>
          )}

          <Footer />

          {/* ... */}
        </div>
      )}
    </>
  );
};

export default VisaPackage;
