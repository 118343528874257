import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import CommonHelmet from "./Components/CommonHelmet";


import { Alert } from "react-bootstrap";
import axios from "axios";
import config from "./config";

const Service = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const handleInput = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function alertBox() {
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  function msgBox() {
    if (showMsg) {
      return (
        <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(config.apiUrl + "contactmail.php", values)
        .then((res) => {
          if (res.data.success) {
            document.querySelector(".form").reset();
            setErrors(res.data.success);
            setShowMsg(true);
            setValues({});
          } else {
            setErrors("Please Try again Later");
            setShow(true);
          }
        })
        .catch((err) => {
          setErrors("Something Wrong Please Try again Later");
          setShow(true);
        });
    } catch {
      console.log("Error");
    }
  };


  const trip = {
    items: 4,
    loop: true,
    margin: 10,
    dots: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {

      0:{
        items:1,

      },

      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <CommonHelmet 
      pageTitle="Tour Operators in Chennai|Tourist Agency in Chennai"
      pageDescription="Tour Operators in Chennai for an enjoyable travel experience.Personalized tours and knowledgeable tour operators are everything you need for your next journey.
      "
      pageKeywords="Best International Packages from Coimbatore, Best Domestic Package from
      Coimbatore, Thailand Package from Coimbatore, Maldives Package from Coimbatore, Singapore
      Package from Coimbatore, Malaysia Package from Coimbatore, Dubai Package from Coimbatore, Best
      Honeymoon Tours from Coimbatore
      "
    />
      <Header />


      <section className="cont-banner">
        <div className="banner-box">
          <h2 className="main-heading">SERVICES</h2>
          <p className="mini-heading">Our Services</p>
        </div>
      </section>
      {/* <!-- service section 1 --> */}
      <section className="main-sec">
        <div className="container">
          <div className="main-box">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12 col-lg-6">
                <div className="main-cont-box">
                  <p className="cursive-heading">
                    Tickets Booked. Bags Packed. Adventure Awaits!
                  </p>
                  <h1 className="main-heading">Your Complete Travel Solutions</h1>
                  <p className="para">
                  Turn your travel dreams into reality with our <b>best international packages from Coimbatore</b>, and explore seamless, stress-free journeys from start to finish. Additionally, discover our best <b>domestic packages from Coimbatore</b> for unforgettable experiences closer to home.
                  </p>
                  <div className="abt-data mb-4">
                    <div className="service-list">
                      <li>
                        <i className="fa-solid fa-check"></i>We offer a wide
                        range of <b>travel tours </b>to destinations all over the world
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>Our travel agents, including our dedicated team of tour operators in Chennai, are available to assist you every step of the way.
                        
                      </li>
                      <li ><i className="fa-solid fa-check"></i>Whether you’re looking for exotic international adventures or memorable domestic getaways, we have the perfect package for you.</li>
                    </div>
                  </div>
                  <Link to="/service/passport">
                    <button className="btns">More Info</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="service-im-box">
                  <img
                    className="im-1"
                    src="asset/images/others/Image Grp3.webp"
                    alt="Adventure"
                    title="Adventure"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end section 1 --> */}

      {/* <!-- section 2 --> */}
      <section className="container">
        <div className="about-top-sec services-main">
          <h5 className="cursive-heading ser">
            Let us help you plan your next adventure
          </h5>
          <h6 className="main-heading ser headtitle">
            Perfect vacation come true
          </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/service/passport"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/passport-icon.webp"
                      alt="Passport"
                      title="Passport"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Passport</h6>
                <p className="para">
                  Empowering Your Dreams to Soar Beyond Borders! Your Passport
                  to Possibilities - Seamlessly delivered with expertise and
                  care. From application to adventure, we've got you covered.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/service/hotel"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/Hotel.webp"
                      alt="Hotel"
                      title="Hotel"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Hotel</h6>
                <p className="para">
                  Your Home Away from Home Awaits! Creating Memories One Stay at
                  a Time - Relax, we'll take care of the rest. Unwind in style
                  and luxury, because you deserve the finest.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/visa"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/visa-1.webp"
                      alt="Visa"
                      title="Visa"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Visa</h6>
                <p className="para">
                  Unlocking World Wonders, One Visa at a Time - Your Gateway to
                  Global Adventures! Seamlessly navigating visas so you can
                  explore with ease, because borders shouldn't hold you back.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/service/insurance"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/insurance-icon.webp"
                      alt="Travel Insurance"
                      title="Travel Insurance"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Travel Insurance</h6>
                <p className="para">
                  Embark on worry-free adventures, protected by our Travel
                  Insurance Guardians! Your Safety Net Across the Globe -
                  Explore with confidence.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/service/ticket"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/Ticket .webp"
                      alt="Ticket"
                      title="Ticket"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Ticket</h6>
                <p className="para">
                  Your Passport to Seamless Journeys - Where Every Destination
                  Begins! Elevating Travel, One Ticket at a Time - We pave the
                  way, you enjoy the ride
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-sec">
                <div className="ser-box">
                  <Link to={"/service/currency"}>
                    <img
                      className="img-fluid"
                      src="asset/images/others/currency-exchange-removebg-preview.webp"
                      alt="Currency"
                      title="Currency Exchange"
                    />
                  </Link>
                </div>
                <h6 className="mini-heading">Currency Exchange</h6>
                <p className="para">
                  Travel the world with ease knowing that your currency exchange
                  is taken care of. We're the currency exchange experts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end section 2 --> */}

      {/* <!--section 3--> */}
      <section className="service-bg">
        <div className="container">
          <div>
            <p className="main-heading">
              Let us Help you Plan your
              <br />
              Next Getaway Now
            </p>

            <a href="/travel-agency">
              <button className="btns">CONTACT US</button>
            </a>
          </div>
        </div>
      </section>
      {/* <!--end section 3--> */}

      {/* <!-- section 4 --> */}
      <section className="service-counter">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-12 col-lg-6">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                  <div className="counter-box">
                    <p className="sub-heading count"></p>
                    <p className="para">
                      Affiliated hotels with all-inclusive service
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="counter-box">
                    <p className="sub-heading count"></p>
                    <p className="para">
                      Affiliated hotels with all-inclusive service
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                  <div className="counter-box">
                    <p className="sub-heading count"></p>
                    <p className="para">
                      Affiliated hotels with all-inclusive service
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="counter-box">
                    <p className="sub-heading count"></p>
                    <p className="para">
                      Affiliated hotels with all-inclusive service
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 justify-content-center align-items-center">
              <div className="counter-para">
                <p className="cursive-heading">Always by your side</p>
                <h6 className="main-heading">The Numbers Say Our Success</h6>
                <p className="para">
                At Aspire Holidays, we believe in quantifying success for unforgettable holiday experiences. Our team of Travel Agents from Coimbatore is dedicated to crafting tailored journeys focused on your needs and preferences.
                </p>
                <Link to="/about">
                  {" "}
                  <button className="btns">Read More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end section 4 --> */}

      {/* <!-- section 5 --> */}
      <section className="serv-form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 bg1">
              <div className="form-img-box">
                <img
                  className="img-fluid"
                  src="asset/images/others/banner1.webp"
                  alt="Best Travel Agency"
                  title="Best Tour Packages"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mini-form">
                <div className="container">
                  <div className="quick-form">
                    <p className="cursive-heading">Plan Your Next Trip</p>
                    <p className="sub-heading">Get in Touch</p>
                    <p className="para">
                      Write to us for personalized travel advice or for
                      information on group travel and last minute travel, all
                      travel is insured and safe.
                    </p>
                  </div>
                  {alertBox()}
                  {msgBox()}
                  <div className="quick-form-box" id="cont">
                    <form className="form" onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={handleInput}
                        required
                      />
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Mobile"
                        onChange={handleInput}
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleInput}
                        required
                      />
                      <textarea
                        rows="5"
                        name="message"
                        placeholder="Your Message"
                        onChange={handleInput}
                        required
                      ></textarea>

                      <button type="submit" className="btns conts-btn">
                        Submit Message
                      </button>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-slides container align-items-center justify-content-center">
          <OwlCarousel className="owl-theme port " loop {...trip}>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/air-asia.webp"
                alt="air-asia"
                title="air-asia"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/air-india.webp"
                alt="air-india"
                title="air-india"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/american.webp"
                alt="american"
                title="american"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/emirates.webp"
                alt="emirates"
                title="emirates"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/etihad.webp"
                alt="etihad"
                title="etihad"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/malaysia.webp"
                alt="malaysia"
                title="malaysia"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/qatar.webp"
                alt="qatar"
                title="qatar"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/singapore.webp"
                alt="singapore"
                title="singapore"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/spicejet.webp"
                alt="spicejet"
                title="spicejet"
              />
            </div>
            <div className="item">
              <img
                src="asset/images/partner_logo_wt/thai.webp"
                alt="thai"
                title="thai"
              />
            </div>
          </OwlCarousel>
        </div>
      </section>
      {/* <!-- end section 5 --> */}

      <Footer />
    </>
  );
};

export default Service;
