import { useEffect, useRef, useState } from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../common/Header.js";
import Footer from "../common/Footer.js";
import Trend from "./Trend.js";
import { Link, useLocation } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';

import Banner from "./Slider.js";

import CommonHelmet from "../Components/CommonHelmet.js";

import { useNavigate } from "react-router-dom";
import config from "../config.js";



const Home = () => {
  
const slides = [
  {
    city: 'Discovery',
    country: 'Encouraging exploration of new places and hidden gems',
    img: '/asset/images/banner/b4.webp',
  },
  {
    city: 'Nature',
    country: 'Focus on eco-tourism and natural beauty',
    img: '/asset/images/banner/b2.webp',
  },
  {
    city: 'Relaxation',
    country: 'Promoting tranquil and serene vacation spots',
    img: '/asset/images/banner/b3.webp',
  },
  {
    city: 'Exotic',
    country: 'Emphasizing unique and culturally rich destinations',
    img: '/asset/images/banner/b5.webp',
  },
  {
    city: 'Adventure',
    country: 'Highlighting exciting travel experiences and outdoor activities',
    img: '/asset/images/banner/b1.webp',
  },
];
  const navigate = useNavigate();
  const [isImageVisible, setImageVisible] = useState(true);
  const carouselRef = useRef(null);
  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };


  const options = {
    items: 2,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const options1 = {
    items: 2,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  const vacation = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const sample = {
    item: 3,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const journey = {
    item: 5,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const Asia = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true, // Hide navigation arrows
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  const banner = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
   
  };
  




  const [pakdata, setPakData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [blog1, setBlog1] = useState([]);
  const [aff, setAff] = useState([]);
  const [tdata, setTdata] = useState([]);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(true);

  const [countryData, setCountryData] = useState([]);

  const [name, setName] = useState("");
  const [world, setWorld] = useState("");
  const [day, setDay] = useState("");

  const [popUp, setPopUp] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const handleClose = () => setShowPopUp(false);

  const handleButtonClick = () => {
    setShowPopUp(false)
  };
  const ImageComponent = ({ src, alt, width, height }) => {
    return (

      <>
        <button onClick={handleButtonClick} type="button" className="btn-close pop_btn" data-bs-dismiss="modal" aria-label="Close"></button>
        <div >
          <Link to={`${popUp?.link ? popUp?.link : "/package-details/1177/ayodhya-&-varanasi-group-tour-package" }`} > <img className="popup-img" src={src} alt={alt} width={width} height={height} /> </Link>
        </div>
      </>
    )
  };

  const PopUpModal = ({ show, handleClose }) => {
    return (
      <Modal className="popup" size="lg" show={show} onHide={handleClose}>
        <Modal.Body>
          <ImageComponent src={`./uploads/popup/${popUp?.popup}`} width={'100%'} height={'100%'} />
        </Modal.Body>
      </Modal>
    );
  };


  useEffect(() => {
    const fetchPopUp = async () => {
      try {
        const res = await axios.get(config.apiUrl + "popup.php");

        if (res.data.suc == "OK") {
          // alert(JSON.stringify(res.data.data.popup))
          setPopUp(res.data.data);

          setTimeout(() => {
            setShowPopUp(true);
          }, 1000);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchPopUp();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(config.apiUrl + "home.php");

        const res1 = await axios.get(config.apiUrl + "countrystate.php");
        setCountryData(res1.data.data);

        setPakData(res.data.packdata);
        setBlog1(res.data.blogs1);
        setBlogs(res.data.blogs);
        setAff(res.data.aff);
        setTdata(res.data.tdata);
        setDate(res.data.formattedDate);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`package?name=${name}&world=${world}&days=${day}`);
  };
  const handleSearch = (e) => {
    e.preventDefault();

    navigate(`package?name=${name}&world=${world}&days=${day}`);
  };

  const finddate = (date) => {
    const [year, month, day] = date.split("-").map(Number);
    const originalDate = new Date(year, month - 1, day);

    const newYear = originalDate.getFullYear() + 1; // Increment year by 1

    const newMonth = originalDate.getMonth(); // Get the month
    const newDay = originalDate.getDate(); // Get the day

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const newMonthName = monthNames[newMonth];

    const formattedNewDate = `${newMonthName} ${newDay < 10 ? "0" + newDay : newDay
      } ${year}`; // Use the input year
    return formattedNewDate;
  };
  const location = useLocation();
  const testimonial = useRef();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get("scrollto");

    if (scrollTo === "testimonial" && testimonial.current) {
      testimonial.current.scrollIntoView({ behavior: "smooth" });
    }

    console.log(scrollTo);
  }, [location]);


  return (
    <>
      <CommonHelmet
        pageTitle="Best Travel Agency in Coimbatore|Travel Agents From Coimbatore"
        pageDescription="Travel Agency in Coimbatore -Discover unparalleled international and domestic travel experiences with our expert travel agents"
        pageKeywords="Best International Packages from Coimbatore, Best Domestic Package from
        Coimbatore, Bali Package from Coimbatore, Thailand Package from Coimbatore, Maldives Package from
        Coimbatore
        "
      />
      <Header />
      {showPopUp && <PopUpModal show={showPopUp} handleClose={handleClose} />}
      <div className="Overflowhide">
        {/* <section className="banner-sec">
          <div id="banner" className="main-banner">
            <video width="100%" autoPlay loop muted>
              <source src="./asset/videos/main_banner.webm" type="video/mp4" />
            </video>
            <div className="slider-cont">
              <h5 className="large-heading">
                Explore the <span>world</span> now at{" "}
                <span>Aspire Holidays</span>
              </h5>
            </div>
          </div>
        </section> */}

        <div className="main-content">
          <Banner slides={slides} />
        </div>






        {/* <!-- Section 3 --> */}
        <section className="tour-explore">
          <div className="container">
            <div className="row">
              <div className="col-md-7 explore">
                <h1 className="cursive-heading">
                  Trusted Travel Agency in Coimbatore
                </h1>
                <p className="main-heading highlightintern">
                  Plan the <a href="/travel-agency">Trip</a> of a Lifetime
                  <br /> with Ease
                </p>
                <p className="travel-agent-highlight">
                  The Best <a href="/travel-agency">Travel Agency</a> in
                  Coimbatore, Aspire Holidays, provides that your next vacation
                  will be very memorable. We personalize every aspect with the
                  help of our knowledgeable staff of travel agents, ensuring a
                  lifetime of priceless memories.
                </p>
                <Link to="/ourcompany">
                  <button className="btns">More Info</button>
                </Link>
              </div>
              <div className="col-md-5">
                <img
                  className="img-fluid j img-trend d-none d-md-block"
                  src="asset/images/others/worldmap.webp"
                  alt="worldmap"
                  title="worldmap"
                />
              </div>
            </div>

            <div className="tour-box">
              <div className="row main-tour-box">
                <div
                  className="col-md-12 col-lg-6"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <Link
                    className="dropdown-item"
                    to="/international-tour-packages"
                  >
                    <div className="box-inn">
                      <div className="row tour tour-box-1">
                        <div className="col-3 icon-box">
                          <img
                            src="/asset/gif/airplane-min.webp"
                            alt="International"
                            title="International"
                          />
                        </div>
                        <h2 className="col-9 txt-box sub-heading textstyle">
                          International
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-md-12 col-lg-6"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="200"
                >
                  <Link className="dropdown-item" to="/domestic-tour-packages">
                    <div className="box-inn">
                      <div className="row tour tour-box-2">
                        <div className="col-3 icon-box">
                          <img
                            src="/asset/gif/train-min.webp"
                            alt="Domestic"
                            title="Domestic"
                          />
                        </div>
                        <h2 className="col-9 txt-box sub-heading textstyle">
                          Domestic
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="row main-tour-box">
                <div
                  className="col-md-12 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="400"
                >
                  <Link className="dropdown-item" to="package?sub=2">
                    <div className="box-inn">
                      <div className="row  tour tour-box-4">
                        <div className="col-3 icon-box">
                          <img
                            src="/asset/gif/honeymoon-min.webp"
                            alt="Honeymoon"
                            title="Honeymoon"
                          />
                        </div>
                        <h2 className="col-9 txt-box sub-heading textstyle">
                          Honeymoon
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-md-12 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="300"
                >
                  <Link className="dropdown-item" to="/package?sub=8">
                    <div className="box-inn">
                      <div className="row tour tour-box-3">
                        <div className="col-3 icon-box">
                          <img
                            src="asset/gif/pilgrimage.gif"
                            alt="Pilgrimage"
                            title=" Pilgrimage"
                          />
                        </div>
                        <h2 className="col-9 txt-box sub-heading textstyle">
                          Pilgrimage
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-md-12 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="500"
                >
                  <Link className="dropdown-item" to="/Visa">
                    <div className="box-inn">
                      <div className="row  tour tour-box-5">
                        <div className="col-3 icon-box">
                          <img
                            src="/asset/gif/visa-ticket.gif"
                            alt="Visa"
                            title="Visa Services"
                          />
                        </div>
                        <h2 className="col-9 txt-box sub-heading textstyle">
                          Visa
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {tdata.length !== 0 && <Trend tdata={tdata} />}


        {/* search */}
        <section className="search-sec">
          <div className="search-inn container">
            <div className="search-cont text-center">
              <p className="cursive-heading">Choose your Trip</p>
              <h2 className="sub-heading highlightintern">Start your <a href="/chennai">Vacation</a> Now</h2>
              <p className="para container highlightintern1">
                Looking for your dream vacation destination but unsure where to
                begin? Let the expertise of the{" "}
                best <b><a href="/travel-agency">Travel Agencies in Coimbatore & </a><a href="/chennai">Chennai</a></b> guide you as you plan
                the trip of a lifetime with ease.
              </p>
            </div>
            <div className="container">
              <div
                className="search-box"
                data-aos="fade-up"
                
                data-aos-duration="800"
              >
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-5 m-lg-0 col-12 col-md-6 col-lg-3">
                      <div className="row input-box">
                        <div className="col-4 ico-box">
                          <span className="fa fa-solid fa-magnifying-glass fa-2xl"></span>
                        </div>
                        <div className="col-8 field-box">
                          <form method="get">
                            <div>
                              <label for="search" className="form-label">
                                Search
                              </label>
                              <br />
                              <input
                                type="text"
                                name="namee"
                                className="form-control unset-form"
                                id="search"
                                placeholder="Insert Keyword"
                                onChange={(e) => setName(e.target.value)}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    handleSearch(e);
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 m-lg-0 col-12 col-md-6 col-lg-3">
                      <div className="row input-box">
                        <div className="col-4 ico-box">
                          <span className="fa fa-solid fa-location-dot fa-2xl"></span>
                        </div>
                        <div className="col-8 field-box">
                          <div>
                            <label for="Destinations" className="form-label">
                              Destinations
                            </label>
                            <br />
                            <select
                              name="world"
                              className="form-select unset-form"
                              aria-label="Default select example"
                              onChange={(e) => setWorld(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleSearch(e);
                                }
                              }}
                            >
                              <option value="">All Destination</option>
                              {countryData.map((data, index) => (
                                <option key={index} value={data.value}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 m-lg-0 col-12 col-md-6 col-lg-3">
                      <div className="row input-box">
                        <div className="col-4 ico-box">
                          <span className="fa fa-solid fa-clock fa-2xl"></span>
                        </div>
                        <div className="col-8 field-box">
                          <div>
                            <label for="Destinations" className="form-label">
                              Duration
                            </label>{" "}
                            <br />
                            <select
                              name="days"
                              className="form-select unset-form"
                              aria-label="Default select example"
                              onChange={(e) => setDay(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleSearch(e);
                                }
                              }}
                            >
                              <option value="">All Duration</option>
                              <option value="5">Upto 5 days</option>
                              <option value="10">Upto 10 days</option>
                              <option value="15">Upto 15 days</option>
                              <option value="20">Upto 20 days</option>
                              <option value="25">Upto 25 days</option>
                              <option value="30">Upto 30 days</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 m-lg-0 col-12 col-md-6 col-lg-3">
                      <div className="d-grid">
                        <button type="submit" className="btns">
                          Search
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="all-package">
          <div className="all-package-inn container">
            <div className="package-cont-box">
              <div className="package-cont">
                <h2 className="cursive-heading">
               <a href="/chennai" className="highlightintern-chennai">Leading Travel Agency in Chennai</a> </h2>
                <h2 className="main-heading pack-heading">
                  Travel Destinations Available Worldwide
                </h2>

                <p className="para highlightintern1">
                  We're the masters of turning 'I need a vacation' into 'I'm on
                  a plane!' Get ready to trade your cubicle for a hammock, and
                  your daily grind for stunning sunsets. We've got more
                  wanderlust-inducing destinations than you can shake a selfie
                  stick at, and we're here to sprinkle your trips with that
                  extra dose of awesome. So buckle up, fellow explorer, and get
                  ready for a wild ride through our virtual vacation
                  wonderland with the <b><a href="/chennai">Best travel agency in Chennai!</a></b>"
                </p>
              </div>
            </div>

            {/* <div className="owl-carousel pop-package owl-theme"> */}
            <OwlCarousel
              className="owl-carousel pop-package owl-theme"
              loop
              margin={10}
              {...Asia}
            >
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/asia.webp"
                    alt="asia"
                    title="asia"
                  />
                  <div className="item-cont">
                    <p className="mini-heading">Asia</p>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/asia" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/pacific.webp"
                    alt="pacific"
                    title="pacific"
                  />
                  <div className="item-cont">
                    <p className="mini-heading">Pacific</p>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/Pacific" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/east.webp"
                    alt="east"
                    title="east"
                  />
                  <div className="item-cont">
                    <p className="mini-heading">East</p>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/middleeast" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/africa.webp"
                    alt="africa"
                    title="africa"
                  />
                  <div className="item-cont">
                    <h2 className="mini-heading">Africa</h2>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/Africa" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/america.webp"
                    alt="america"
                    title="america"
                  />
                  <div className="item-cont">
                    <h2 className="mini-heading">America</h2>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/america" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <img
                    src="/asset/images/country/europe.webp"
                    alt="europe"
                    title="europe"
                  />
                  <div className="item-cont">
                    <h2 className="mini-heading">Europe</h2>
                    <p>Here is filled with diverse culture and cities</p>
                    <Link to="/Europe" className="btns">
                      View Packages
                    </Link>
                  </div>
                </div>
              </div>
            </OwlCarousel>
            {/* </div> */}
          </div>
        </section>

        {/* <!--  Section 5 --> */}
        <section className="about-sec">
          <div className="container">
            <div className="row about-row">
              <div className="col-md-12 col-lg-6">
                {/* <div
                className="owl-carousel about-owl owl-theme"
                data-aos="fade-right"
                data-aos-duration="1500"
              > */}
                <OwlCarousel
                  className="owl-theme about-owl "
                  loop
                  {...vacation}
                >
                  <div className="item">
                    <img
                      height="100%"
                      width="auto"
                      src="./asset/images/others/girl6.webp"
                      alt="Vacation"
                      title="Vacation"
                    />
                  </div>
                  <div className="item">
                    <img
                      height="100%"
                      width="auto"
                      src="./asset/images/others/cap-boy.webp"
                      alt="Vacation"
                      title="Vacation"
                    />
                  </div>
                  {/* </div> */}
                </OwlCarousel>
              </div>

              <div className="col-md-12 col-lg-6">
                <h5 className="cursive-heading">Discover Aspire Holidays</h5>
                <h5 className="main-heading">Planning your Vacation</h5>
                <p>
                  Aspire Holidays has everything you need to make the most of
                  your next dream holiday, one of the{" "}
                  <b>best travel agency in Coimbatore</b>, offers the{" "}
                  <b>best international packages from Coimbatore</b> dedicated
                  to make your next vacation the best it can be.
                </p>

                <Link to="/package">
                  <button className="btns">Know More</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* < Section 5 end  --> */}

        {/* Section 6  */}
        <section className="travel-video">
          <div className="travel-video-box">
            <h4
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="large-heading zoom-txt"
            >
              Boundless Journeys
            </h4>
            <div className="video-box">
              <video
                data-aos="zoom-out"
                data-aos-duration="1000"
                className="zoom-vid"
                width="85%"
                playsinline
                autoPlay
                loop
                muted
                autobuffer
                preload="auto"
                poster="asset/images/2.webp"
              >
                <source src="asset/videos/travel_girls.webm" type="video/mp4" />
              </video>
            </div>
            <div className="partner-box container">
              <OwlCarousel className="owl-theme about-owl " loop {...journey}>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/airasia-blue.webp"
                    alt="airasia"
                    title="airasia"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/airindia-blue.webp"
                    alt="airindia"
                    title="airindia"
                  />
                </div>

                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/airasia-blue.webp"
                    alt="airasia"
                    title="airasia"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/airindia-blue.webp"
                    alt="airindia"
                    title="airindia"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/american-blue.webp"
                    alt="american"
                    title="american"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/emirates-blue.webp"
                    alt="emirates"
                    title="emirates"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/etihad.webp"
                    alt="etihad"
                    title="etihad"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/malaysia-blue.webp"
                    alt="malaysia"
                    title="malaysia"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/qatar-blue.webp"
                    alt="qatar"
                    title="qatar"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/singapore-blue.webp"
                    alt="singapore"
                    title="singapore"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/spicejet-blue.webp"
                    alt="spicejet"
                    title="spicejet"
                  />
                </div>
                <div className="item p-3">
                  <img
                    className="img-fluid"
                    src="asset/images/partner_logo/thai-blue.webp"
                    alt="thai"
                    title="thai"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="review-sec" ref={testimonial}>
          <div className="review-title container">
            <div className="row align-content-center">
              <div className="col-md-8 text-center text-md-start">
                <h2 className="main-heading">Customer Reviews</h2>
                <p className="customer-review-keyword">
                  Our customers' feedback, especially on{" "}
                  <a href="/domestic-tour-packages">
                    Best Domestic Tour Packages
                  </a>
                  , is essential for building a great reputation and maintaining
                  excellent service. By listening to our customers' needs, we
                  can improve our offerings and provide an exceptional travel
                  experience.
                </p>
              </div>
              <div
                className="col-md-4 d-none d-md-block re-img"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <img
                  src="asset/images/others/girl1.webp"
                  alt="Customer Reviews"
                  title="Customer Reviews"
                />
              </div>
            </div>
          </div>
          <div className="review-box container">
            {/* <div className="owl-carousel review-slide owl-theme"> */}
            <OwlCarousel
              className="owl-theme review-slide"
              loop
              margin={10}
              {...options1}
            >
              <div className="item ">
                <p className="review-txt">
                  We've planned our all India educational tour program to visit
                  many north indian cities including Hyderabad mumbai delhi and
                  so on. so we seeked the guidance of aspire holidays. Our tour
                  experience with Ashok brother as tourist guide was really good
                  and memorable.
                </p>
                <div className="review-person">
                  <div className="img-box">
                    <img
                      src="asset/images/others/girl1.webp"
                      alt="Customer Reviews"
                      title="Customer Reviews"
                    />
                  </div>
                  <div className="name-box">
                    <p className="mini-heading">JINS JANEL</p>
                    <p>Chennai</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <p className="review-txt">
                  It was a great trip for all of us who had visited vagomon for
                  the first time and we had great time in both kochi and
                  vagamon. Mr. Sanjay had been a great guide and did a lot of
                  things for the trip he made this trip memorable for all of us.
                  Thanks you sanjay sir for planning so much for us
                </p>
                <div className="review-person">
                  <div className="img-box">
                    <img
                      src="asset/images/others/girl1.webp"
                      alt="Customer Reviews"
                      title="Customer Reviews"
                    />
                  </div>
                  <div className="name-box">
                    <p className="mini-heading">Janani Angurajan</p>
                    <p>Banglore</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>

          {/* </div> */}

          {/* </div> */}
        </section>

        <section className=" price-content-box">
          <div className="container">
            <div className="price-first-box">
              <h2 className="cursive-heading">Latest Tour Package Price</h2>
              <h2 className="main-heading">Affordable Tour Packages</h2>
              <p className="para">
                We believe that everyone deserves to experience their dream
                vacation without breaking the bank. That's why we offer a price
                section on our website that features a range of affordable
                travel
              </p>
            </div>
            <div className="row justify-content-center ">
              {/* <?php foreach ($aff as $da) { ?> */}
              {!loading && (
                <OwlCarousel loop margin={10} {...sample}>
                  {aff
                    .sort(() => Math.random() - 0.5)
                    .map((aff) => (
                      <div className="box1-con m-3">
                        <div className="tit-box">
                          <h2 className="mini-heading">
                            {/* <?php echo $da['name']; ?> */}
                            {aff.name}
                          </h2>
                        </div>
                        <br />
                        <h6 className="main-heading">
                          ₹{/* <?php echo $da['amount']; ?>*/}
                          {aff.amount}
                          <span className="price">/ Day</span>
                        </h6>
                        <ul>
                          <li>
                            <i className="fa-solid fa-check"></i>VISA :
                            {/* <?php echo $da['visa_title']; ?> */}
                            {aff.visa_title}
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Passport:
                            {/* <?php echo $da['passport_title']; ?> */}
                            {aff.passport_title}
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Ticket:
                            {/* <?php echo $da['ticket_title']; ?> */}
                            {aff.ticket_title}
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Transport:
                            {/* <?php echo $da['transport_title']; ?> */}
                            {aff.transport_title}
                          </li>
                          <li>
                            <i className="fa-solid fa-check"></i>Duration :
                            {/* <?php echo $da['tdays']; ?> Days */}
                            {aff.tdays} &nbsp; Days
                          </li>
                        </ul>
                        <Link
                          to={`package-details/${aff.id}/${aff.name
                            ?.trim()
                            .replace(/\s/g, "-")
                            .toLowerCase()}`}
                        >
                          <button className="btns">View Details</button>
                        </Link>
                      </div>
                    ))}
                </OwlCarousel>
              )}
              {/* <?php } ?> */}
            </div>
          </div>
        </section>
        {/* <!-- Section 9  --> */}
        <section className="data-sec">
          <div className="data-sec-inn container">
            <div className="row">
              <div className="mb-5 col-md-12 col-lg-6">
                <div className="data-cont">
                  <p className="cursive-heading">Wandering Souls</p>
                  <h2 className="main-heading">Discover Your Next Adventure</h2>
                  <p className="para">
                    Whether you're planning a romantic honeymoon or a family
                    vacation, our price section, featuring the Best{" "}
                    <b>Honeymoon Tours in Coimbatore</b>, has got you covered.
                    So, start browsing today and find the perfect vacation
                    package at a price that won't leave you feeling guilty.
                  </p>
                  <div className="data-links">
                    <h6 className="para">International Tour Packages</h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <h6 className="para">Domestic Tour Packages</h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "85%" }}
                        aria-valuenow="85"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <h6 className="para">Honeymoon Tour Packages</h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="clr">
                    <Link to="package" className="btns">
                      More Info
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mb-5 col-md-12 col-lg-6">
                <div
                  className="data-img"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="./asset/images/others/girl3.webp"
                      alt="Adventure"
                      title="Adventure"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section 9 end  --> */}

        <section className="blog-sec">
          <div className="blog-sec-inn container">
            <div className="blog-head">
              <p className="cursive-heading">Roaming Tales</p>
              <h3 className="main-heading">Travel Blog</h3>
              <p className="para">
                We share our experiences, tips, and travel stories to inspire
                and guide our readers in their own wanderlust adventures. From
                hidden gems to popular destinations, including family vacation
                packages in Coimbatore, we showcase the beauty and diversity of
                the world, and promote responsible and sustainable travel.
              </p>
              <br />
              <div className="clr">
                <Link to={"/Blog"} className="btns">
                  View All
                </Link>
              </div>
            </div>

            <div className="blog-main">
              <div className="row">
                <div className="col-md-12 col-lg-6  mb-md-5">
                  <div className="main-blog-item">
                    <div className="blog-img">
                      <Link
                        to={`blog_details/${blog1.id}/${blog1.name
                          ?.trim()
                          .replace(/\s/g, "-")
                          .toLowerCase()}`}
                      >
                        <img
                          alt="blog"
                          title="blog"
                          className="img-fluid"
                          src={`/uploads/blog/${blog1.img}`}
                        />
                      </Link>
                    </div>
                    <div className="blog-body">
                      <Link
                        to={`blog_details/${blog1.id}/${blog1.name
                          ?.trim()
                          .replace(/\s/g, "-")
                          .toLowerCase()}`}
                      >
                        {date}
                      </Link>
                      <Link
                        className="blogg__name"
                        to={`blog_details/${blog1.id}/${blog1.name
                          ?.trim()
                          .replace(/\s/g, "-")
                          .toLowerCase()}`}
                      >
                        <h2 className="sub-heading">{blog1.name}</h2>
                      </Link>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: `${blog1.intro?.slice(0, 200)}...`,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-6 ">
                  <div className="sub-blog-item">
                    {/* <?php foreach ($blogs as $b) {
              $dateCreated1 = new DateTime($b['date_created']);
              $formattedDate1 = $dateCreated1->format('F j, Y');
              $shortContent = substr($b['content'], 0, 70) . '...';
              ?> */}
                    {/* sample */}
                    {blogs.map((blog) => (
                      <div className="row">
                        <div className="col-md-4">
                          <div className="blog-img">
                            <Link
                              to={`blog_details/${blog.id}/${blog.name
                                ?.trim()
                                .replace(/\s/g, "-")
                                .toLowerCase()}`}
                            >
                              <img
                                alt="blog"
                                title="Taj Mahal"
                                className="img-fluid"
                                src={`uploads/blog/${blog.img}`}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="blog-body">
                            <Link
                              to={`/blog_details/${blog.id}/${blog.name
                                ?.trim()
                                .replace(/\s/g, "-")
                                .toLowerCase()}`}
                            >
                              { }
                              {finddate(blog.date_created)}
                            </Link>
                            <Link
                              className="blogg__name"
                              to={`/blog_details/${blog.id}/${blog.name
                                ?.trim()
                                .replace(/\s/g, "-")
                                .toLowerCase()}`}
                            >
                              <h2 className="mini-heading">{blog.name}</h2>
                            </Link>
                            <p
                              className="para"
                              dangerouslySetInnerHTML={{
                                __html: `${blog.intro?.slice(0, 100)}...`,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* <?php } ?> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>



      <Footer />
    </>
  );
};

export default Home;
