import React, { useEffect, useState } from "react";
import "../css/Header.css";
import { Link, json } from "react-router-dom";
import axios from "axios";
import config from "../config";

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setDropdownOpen3] = useState(false);
  const [menu, setMenu] = useState(false);
  const [countryData, setCountryData] = useState([]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleDropdown1 = () => {
    setDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2(!isDropdownOpen2);
  };
  const toggleDropdown3 = () => {
    setDropdownOpen3(!isDropdownOpen3);
  };

  const [loading, setLoading] = useState(false);

  const [domestic, setDomestic] = useState([]);
  const [asia, setAsia] = useState([]);
  const [africa, setAfrica] = useState([]);
  const [america, setAmerica] = useState([]);
  const [europe, setEurope] = useState([]);
  const [pacific, setPacific] = useState([]);
  const [east, setEast] = useState([]);
  const [pilgrimCountry, setPilgrimCountry] = useState([]);
  const [pilgrimState, setPilgrimState] = useState([]);

  //honeymoon
  const [asia1, setAsia1] = useState([]);
  const [africa1, setAfrica1] = useState([]);
  const [america1, setAmerica1] = useState([]);
  const [europe1, setEurope1] = useState([]);
  const [pacific1, setPacific1] = useState([]);
  const [east1, setEast1] = useState([]);
  const [domestic1, setDomestic1] = useState([]);

  //criuses
  const [asia2, setAsia2] = useState([]);
  const [africa2, setAfrica2] = useState([]);
  const [america2, setAmerica2] = useState([]);
  const [europe2, setEurope2] = useState([]);
  const [pacific2, setPacific2] = useState([]);
  const [east2, setEast2] = useState([]);
  const [domestic2, setDomestic2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const asiaResponse = await axios.get(config.apiUrl + "asia.php");
        setAsia(asiaResponse.data.country);

        const asiahm = await axios.get(config.apiUrl + "asia_hm.php");
        setAsia1(asiahm.data.country);

        const africhm = await axios.get(config.apiUrl + "africa_hm.php");
        setAfrica1(africhm.data.country);
        const easthm = await axios.get(config.apiUrl + "east_hm.php");
        setEast1(easthm.data.country);
        const domestichm = await axios.get(config.apiUrl + "domestic_hm.php");
        setDomestic1(domestichm.data.state);
        const europehm = await axios.get(config.apiUrl + "europe_hm.php");
        setEurope1(europehm.data.country);

        const africaResponse = await axios.get(config.apiUrl + "africa.php");
        setAfrica(africaResponse.data.country);

        const americaResponse = await axios.get(config.apiUrl + "america.php");
        setAmerica(americaResponse.data.country);

        const europeResponse = await axios.get(config.apiUrl + "europe.php");
        setEurope(europeResponse.data.country);

        const eastResponse = await axios.get(config.apiUrl + "east.php");
        setEast(eastResponse.data.country);

        const pacificResponse = await axios.get(config.apiUrl + "pacific.php");
        setPacific(pacificResponse.data.country);

        const domesticResponse = await axios.get(
          config.apiUrl + "domestic.php"
        );
        setDomestic(domesticResponse.data.country);

        const pilgrimResponse = await axios.get(
          config.apiUrl + "pilgrimage-menu.php"
        );
        setPilgrimCountry(pilgrimResponse.data.country);
        setPilgrimState(pilgrimResponse.data.state);

        const res1 = await axios.get(config.apiUrl + "countrystate.php");
        setCountryData(res1.data.data);

        const americahm = await axios.get(config.apiUrl + "america_hm.php");
        setAmerica1(americahm.data.country);
        const pacifichm = await axios.get(config.apiUrl + "pacific_hm.php");
        setPacific1(pacifichm.data.country);

        const asiacs = await axios.get(config.apiUrl + "asia_cs.php");
        setAsia2(asiacs.data.country);
        const europecs = await axios.get(config.apiUrl + "europe_cs.php");
        setEurope2(europecs.data.country);
        const americacs = await axios.get(config.apiUrl + "america_cs.php");
        setAmerica2(americacs.data.country);
        const eastcs = await axios.get(config.apiUrl + "east_cs.php");
        setEast2(eastcs.data.country);
        const pacifichcs = await axios.get(config.apiUrl + "pacific_cs.php");
        setPacific2(pacifichcs.data.country);
        const africacs = await axios.get(config.apiUrl + "africa_cs.php");
        setAfrica2(africacs.data.country);
        const domesticcs = await axios.get(config.apiUrl + "domestic_cs.php");
        setDomestic2(domesticcs.data.state);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="main-menu" id="top">
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to={"/"}>
              <img
                src="/asset/images/others/logo.svg"
                alt="aspire_logo"
                title="aspire_logo"
              />
            </Link>
            <button
              onClick={() => setMenu((prev) => !prev)}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded={menu ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse ${menu ? "collapse show" : ""
                }`}
              id="navbarScroll"
            >
              <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll nav-scr">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to={"/"}>
                    Home
                  </Link>
                </li>
                <li
                  className={`nav-item dropdown ${isDropdownOpen1 ? "show" : ""
                    }`}
                  onMouseEnter={toggleDropdown1}
                  onMouseLeave={toggleDropdown1}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded={isDropdownOpen1 ? "true" : "false"}
                    href="/about"
                  >
                    About
                  </a>
                  <ul
                    className={`dropdown-menu ${isDropdownOpen1 ? "show" : ""}`}
                  >
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/ourcompany"}
                      >
                        Our Company &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/?scrollto=testimonial"}
                      >
                        Testimonial &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/blog"}
                      >
                        Blogs &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                      >
                        Career &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`nav-item dropdown ${isDropdownOpen ? "show" : ""
                    }`}
                  onMouseEnter={toggleDropdown}
                  onMouseLeave={toggleDropdown}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                  >
                    Packages
                  </a>

                  <ul
                    className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  >
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/international-tour-packages"}
                      >
                        International &nbsp;&nbsp;{" "}
                        <i class="fa-solid fa-caret-right fa-sm"></i>
                      </Link>
                      {!loading && (
                        <div className="sub-menu-box continetal-bx">
                          <div className="row sub-menu-row">
                            <div className="col-3">
                              {europe && (
                                <>
                                  <h6>Europe</h6>
                                  {europe.map((country, index) => (
                                    <ul key={index}>
                                      {countryData.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                            </div>
                            <div className="col-3">
                              {asia && (
                                <>
                                  <h6>Asia</h6>
                                  {asia?.map((country, index) => (
                                    <ul key={index}>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                            </div>
                            <div className="col-3">
                              {east && (
                                <>
                                  <h6>East</h6>
                                  {east?.map((country, index) => (
                                    <ul key={index}>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name ===
                                                    "United Arab Emirates"
                                                    ? "UAE"
                                                    : country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                              {america && (
                                <>
                                  <h6>America</h6>
                                  {america?.map((country, index) => (
                                    <ul key={index}>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                            </div>
                            <div className="col-3">
                              {africa && (
                                <>
                                  <h6>Africa</h6>
                                  {africa?.map((country, index) => (
                                    <ul key={index}>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                              {pacific && (
                                <>
                                  <h6>Pacific</h6>
                                  {pacific?.map((country, index) => (
                                    <ul key={index}>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=6&cat=1&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </ul>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                    <li className="cbox2">
                      <Link
                        className="dropdown-item"
                        to={"/domestic-tour-packages"}
                      >
                        Domestic &nbsp;&nbsp;{" "}
                        <i class="fa-solid fa-caret-right fa-sm"></i>
                      </Link>
                      <div className="sub-menu-box india-bx">
                        <div className="row sub-menu-row">
                          <div className="col-12">
                            <ul className="cm-ul">
                              {domestic?.map((state) => (
                                <>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      state.state
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?sub=6&cat=2&state=${country1.value}`}
                                          >
                                            <li className="cm-li" key={index}>
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="cbox1">
                      <Link className="dropdown-item" to={"/package?sub=8"}>
                        Pilgrimage &nbsp;&nbsp;{" "}
                        <i class="fa-solid fa-caret-right fa-sm"></i>
                      </Link>
                      {
                        // pilgrimState.length > 0 && pilgrimCountry.length > 0 &&
                        <>
                          <div className="sub-menu-box continetal-bx">
                            {pilgrimState.length > 0 && (
                              <>
                                <h6>Domestic</h6>
                                <ul className="cm-ul pil-menu">
                                  {pilgrimState?.map((state) => (
                                    <>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          state.state
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?cat=2&sub=8&state=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </>
                                    // <Link
                                    //   to={`/package?state=${state.state}`}
                                    // >
                                    //   <li className="cm-li">{state.state}</li>
                                    // </Link>
                                  ))}
                                </ul>{" "}
                              </>
                            )}

                            {pilgrimCountry.length > 0 && (
                              <>
                                <h6>International</h6>
                                <ul className="cm-ul pil-menu">
                                  {pilgrimCountry?.map((country) => (
                                    <>
                                      {countryData?.map(
                                        (country1, index) =>
                                          country1.value ===
                                          country.country
                                            .replace(/\s/g, "")
                                            .toLowerCase() && (
                                            <>
                                              <Link
                                                to={`/package?sub=8&country=${country1.value}`}
                                              >
                                                <li
                                                  className="cm-li"
                                                  key={index}
                                                >
                                                  {country1.name}
                                                </li>
                                              </Link>
                                            </>
                                          )
                                      )}
                                    </>
                                    // <Link
                                    //   to={`/package?country=${country.country}`}
                                    // >
                                    //   <li className="cm-li">
                                    //     {country.country}
                                    //   </li>
                                    // </Link>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </>
                      }
                    </li>
                    {/* <li className="cbox1">
                      <Link className="dropdown-item" to={"/package?sub=2"}>
                        Honeymoon &nbsp;&nbsp;{" "}
                        <i class="fa-solid fa-caret-right fa-sm"></i>
                      </Link>
                      <div className="sub-menu-box sub-menu-box1 continetal-bx">

                        <div className="row sub-menu-row">
                          {europe1 && (
                            <>
                              <div className="col-3">
                                <h6>Europe</h6>
                                {europe1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}
                          {domestic1 && (
                            <>
                              <div className="col-3">
                                <h6>Domestic</h6>
                                {domestic1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.state
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=2&sub=2&country=${country1.value}`}
                                            >
                                              <li
                                                className="cm-li"
                                                key={index}
                                              >
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}
                          {asia1 && (
                            <>
                              <div className="col-3">
                                <h6>Asia</h6>
                                {asia1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}

                          <div className="col-3">
                            {east1 && (
                              <>
                                <h6>East</h6>
                                {east1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name ===
                                                  "United Arab Emirates"
                                                  ? "UAE"
                                                  : country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                            {america1 && (
                              <>
                                <h6>America</h6>
                                {america1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name ===
                                                  "United Arab Emirates"
                                                  ? "UAE"
                                                  : country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                          </div>
                          <div className="col-3">
                            {africa1 && (
                              <>
                                <h6>Africa</h6>
                                {africa1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                            {pacific1 && (
                              <>
                                <h6>Pacific</h6>
                                {pacific1?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=2&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li> */}
                    {/* <li className="cbox1">
                      <Link className="dropdown-item" to={"/package?sub=3"}>
                        Cruises &nbsp;&nbsp;{" "}
                        <i class="fa-solid fa-caret-right fa-sm"></i>
                      </Link>
                      <div className="sub-menu-box sub-menu-box1 sub-menu-box2 continetal-bx">
                        <div className="row sub-menu-row">
                          {europe2 && (
                            <>
                              <div className="col-3">
                                <h6>Europe</h6>
                                {europe2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}

                          {asia2 && (
                            <>
                              <div className="col-3">
                                <h6>Asia</h6>
                                {asia2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}
                          {domestic2 && (
                            <>
                              <div className="col-3">
                                <h6>Domestic</h6>
                                {domestic2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country?.replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </div>
                            </>
                          )}
                          <div className="col-3">
                            {east2 && (
                              <>
                                <h6>East</h6>
                                {east2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name ===
                                                  "United Arab Emirates"
                                                  ? "UAE"
                                                  : country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                            {america2 && (
                              <>
                                <h6>America</h6>
                                {america2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name ===
                                                  "United Arab Emirates"
                                                  ? "UAE"
                                                  : country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                          </div>
                          <div className="col-3">
                            {africa2 && (
                              <>
                                <h6>Africa</h6>
                                {africa2.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                            {pacific2 && (
                              <>
                                <h6>Pacific</h6>
                                {pacific2?.map((country, index) => (
                                  <ul key={index}>
                                    {countryData?.map(
                                      (country1, index) =>
                                        country1.value ===
                                        country.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && (
                                          <>
                                            <Link
                                              to={`/package?cat=1&sub=3&country=${country1.value}`}
                                            >
                                              <li className="cm-li" key={index}>
                                                {country1.name}
                                              </li>
                                            </Link>
                                          </>
                                        )
                                    )}
                                  </ul>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item dropdown ${isDropdownOpen3 ? "show" : ""} cbox1`}
                  onMouseEnter={toggleDropdown3}
                  onMouseLeave={toggleDropdown3}

                >
                  <a
                    className="nav-link dropdown-toggle dropdown-item"
                    role="button"
                    aria-expanded={isDropdownOpen3 ? "true" : "false"}
                    href="/package?sub=2"
                  >
                    Honeymoon
                  </a>


                  
                    <div className={`dropdown-menuu dropdown-menu dropdown_hm ${isDropdownOpen3 ? "show" : ""}`}>
                    <div className="sub-menu-boxx sub-menu-box1 continetal-bx">
                      <div className="row sub-menu-row ">
                        {europe1 && (
                          <>
                            <div className="col-3">
                              <h6>Europe</h6>
                              {europe1?.map((country, index) => (
                                <ul key={index}>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      country.country
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?cat=1&sub=2&country=${country1.value}`}
                                          >
                                            <li className="cm-li" key={index}>
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </ul>
                              ))}
                            </div>
                          </>
                        )}
                        {domestic1 && (
                          <>
                            <div className="col-3">
                              <h6>Domestic</h6>
                              {domestic1?.map((country, index) => (
                                <ul key={index}>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      country.state
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?cat=2&sub=2&country=${country1.value}`}
                                          >
                                            <li
                                              className="cm-li"
                                              key={index}
                                            >
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </ul>
                              ))}
                            </div>
                          </>
                        )}
                        {asia1 && (
                          <>
                            <div className="col-3">
                              <h6>Asia</h6>
                              {asia1?.map((country, index) => (
                                <ul key={index}>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      country.country
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?cat=1&sub=2&country=${country1.value}`}
                                          >
                                            <li className="cm-li" key={index}>
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </ul>
                              ))}
                            </div>
                          </>
                        )}


                        <div className="col-3">
                          {africa1 && (
                            <>
                              <h6>Africa</h6>
                              {africa1?.map((country, index) => (
                                <ul key={index}>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      country.country
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?cat=1&sub=2&country=${country1.value}`}
                                          >
                                            <li className="cm-li" key={index}>
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </ul>
                              ))}
                            </>
                          )}
                          {pacific1 && (
                            <>
                              <h6>Pacific</h6>
                              {pacific1?.map((country, index) => (
                                <ul key={index}>
                                  {countryData?.map(
                                    (country1, index) =>
                                      country1.value ===
                                      country.country
                                        .replace(/\s/g, "")
                                        .toLowerCase() && (
                                        <>
                                          <Link
                                            to={`/package?cat=1&sub=2&country=${country1.value}`}
                                          >
                                            <li className="cm-li" key={index}>
                                              {country1.name}
                                            </li>
                                          </Link>
                                        </>
                                      )
                                  )}
                                </ul>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to={"/visa"} className="nav-link">
                    Visa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/service"} className="nav-link">
                    Services
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to={"/blog"} className="nav-link">
                    Blogs
                  </Link>
                </li> */}
                <li
                  className={`nav-item dropdown ${isDropdownOpen1 ? "show" : ""
                    }`}
                  onMouseEnter={toggleDropdown2}
                  onMouseLeave={toggleDropdown2}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded={isDropdownOpen2 ? "true" : "false"}
                  >
                    Contact
                  </a>
                  <ul
                    className={`dropdown-menu ${isDropdownOpen2 ? "show" : ""}`}
                  >
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/travel-agency"}
                      >
                        Coimbatore &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/chennai"}
                      >
                        Chennai &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                    <li className="cbox1">
                      <Link
                        className="dropdown-item"
                        to={"/salem"}
                      >
                        Salem &nbsp;&nbsp;{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div>
                <div className="social1">
                  <a
                    className="sm1"
                    href="https://facebook.com/aspireholidays"
                    title="Facebook"
                  >
                    <div className="s-media1">
                      <i className="fa-brands fa-facebook-f"></i>
                    </div>
                  </a>
                  <a
                    className="sm1"
                    href="https://www.instagram.com/aspireholidays.in/"
                    title="Instagram"
                  >
                    <div className="s-media2">
                      <i className="fa-brands fa-instagram"></i>
                    </div>
                  </a>
                  <a
                    className="sm1"
                    href="https://www.youtube.com/@AspireHolidays"
                    title="Youtube"
                  >
                    <div className="s-media3">
                      <i className="fa-brands fa-youtube"></i>
                    </div>
                  </a>
                  <a
                    className=" sm1"
                    href="https://wa.me/+919362266666"
                    title="Whatsapp"
                  >
                    <div className="s-media4">
                      <i className="fa-brands fa-whatsapp"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Header;
