import React, { useEffect } from "react";
import "../css/Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { RiCheckDoubleFill } from "react-icons/ri"; // Import tick-style icon

const Footer = ({ setOurpackage }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const sentencesWithLinks = [
    { sentence: "Travel Agents from Coimbatore", link: "/" },
    { sentence: "Best Travel Agency in Coimbatore", link: "/about" },
    {
      sentence: "Best International Tour Packages",
      link: "/international-tour-packages",
    },
    {
      sentence: "Best Domestic Package from Coimbatore",
      link: "/domestic-tour-packages",
    },
    {
      sentence: "Bali Package from Coimbatore",
      link: "/package?name=bali&world=&days=",
    },
    {
      sentence: "Thailand Package from Coimbatore",
      link: "/package?name=&world=thailand&days=",
    },
    {
      sentence: "Maldives Package from Coimbatore",
      link: "/package?name=&world=maldives&days=",
    },
    {
      sentence: "Singapore Package from Coimbatore",
      link: "/package?name=&world=singapore&days=",
    },
    {
      sentence: "Malaysia Package from Coimbatore",
      link: "/package?name=&world=malaysia&days=",
    },
    {
      sentence: "Dubai Package from Coimbatore",
      link: "/package?name=&world=dubai&days=",
    },
    {
      sentence: "Manali Package from Coimbatore",
      link: "/package?name=&world=manali&days=",
    },
    {
      sentence: "Best Honeymoon Tours from Coimbatore",
      link: "/package?name=&world=honeymoon&days=",
    },
    { sentence: "Best travel agents in coimbatore", link: "/about" },
    {
      sentence: "family vacation packages in Coimbatore ",
      link: "/international-tour-packages",
    },
    {
      sentence: "Kashmir Package from Coimbatore",
      link: "/package?name=kashmir&world=&days=",
    },
    { sentence: "best travel websites in coimbatore", link: "/about" },
    { sentence: "best tour agency in coimbatore", link: "/about" },
    {
      sentence: "vacation websites in coimbatore ",
      link: "/domestic-tour-packages",
    },
    { sentence: "top travel agencies in coimbatore  ", link: "/" },
    {
      sentence: "top travel sites in coimbatore",
      link: "/domestic-tour-packages",
    },
    {
      sentence: "travel agency coimbatore",
      link: "/international-tour-packages",
    },
    {
      sentence: "top international travel agents in coimbatore",
      link: "/domestic-tour-packages",
    },
    { sentence: "cheap and best travel in coimbatore", link: "/" },
    { sentence: "tour packages from coimbatore", link: "/" },

    // ... add more sentences and links as needed
  ];

  const linkStyle = {
    textDecoration: "none",
    color: "rgba(255, 254, 254, 0.733) ", // Set the color to white
  };

  return (
    <>
      <section className="footer-bg"></section>
      <section className="footer-sec">
        <div className="footer-sec-inn">
          <div className="row first-row">
            <div className="col-md-12 col-lg-8 mb-5">
              <div
                className="txt-box"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <h2 className="main-heading">
                  Travel beyond your imagination, with our Travel Agency!
                </h2>
              </div>
              <div className="social-icons">
                <a href="https://www.instagram.com/aspireholidays.in/">
                  <span
                    className="fa-brands fa-xl fa-instagram"
                    title="Instagram"
                  ></span>
                </a>
                <a href="https://www.linkedin.com/in/aspire-holidays-52b626144/?originalSubdomain=in">
                  <span
                    className="fa-brands fa-xl fa-linkedin"
                    title="Linkedin"
                  ></span>
                </a>
                <a href="https://www.youtube.com/@AspireHolidays">
                  <span
                    className="fa-brands fa-xl fa-youtube"
                    title="Youtube"
                  ></span>
                </a>
                <a href="https://twitter.com/aspireholidays">
                  <span
                    className="fa-brands fa-xl fa-twitter"
                    title="Twitter"
                  ></span>
                </a>
                <a href="https://wa.me/+919362266666">
                  <span
                    className="fa-brands fa-xl fa-whatsapp"
                    title="Whatsapp"
                  ></span>
                </a>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="footer-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-box">
                      <p className="mini-heading">Contact</p>
                      <a href="mailto:info@aspireholidays.in" className="btns ">
                        info@aspireholidays.in
                      </a>
                      <div className="phone-box">
                        <a href="tel:9362266666" className="mini-heading">
                          +91 9362266666
                        </a>
                        <br />
                        <a href="tel:9514433334" className="mini-heading">
                          +91 9514433334
                        </a>
                      </div>
                      <br /> <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row address-box">
              <div className="col-md-4">
                <p className="mini-heading">Corporate Office Address<
                  /p>
                <p className="mini-heading">Coimbatore</p>
                <address>
                  Second Floor, Nagammai Building,
                  <br /> Dr Nanjappa Road, Near Park Gate Roundana,
                  <br /> Park Gate, Ram Nagar, Coimbatore,
                  <br /> Tamil Nadu 641018
                </address>
              </div>
              
              <div className="col-md-4">
              <p className="mini-heading">Our Branches</p>
                <p className="mini-heading">Chennai</p>
                <address>
                  Prince Centre, Ground Floor,
                  <br /> New No: 248, Pathari Road, Anna Salai, Chennai, Tamil
                  Nadu 600006
                </address>
              </div>
              <div className="col-md-4 mt-lg-5 mt-md-5 mt-sm-0">
                <p className="mini-heading">Salem</p>
                <address>
                  5/34 - A, MVS Nagar 4th Cross, <br />
                  Thalavaipatti, Salem - 636302
                </address>
              </div>
            </div>
            {/* <div className="row address-box">
              
              <div className="col-md-6">
                <p className="mini-heading">Chennai</p>
                <address>
                  Prince Centre, Ground Floor,
                  <br /> New No: 248, Pathari Road, Anna Salai, Chennai, Tamil
                  Nadu 600006
                </address>
              </div>
              <div className="col-md-6">
                <p className="mini-heading">Salem</p>
                <address>
                  5/34 - A, MVS Nagar 4th Cross, <br />
                  Thalavaipatti, Salem - 636302
                </address>
              </div>
            </div> */}
          </div>
          <div className="row second-row">
            <div className="col-md-6 col-lg-2 mb-5">
              <div className="ql-list">
                <p className="mini-heading">Quick Links</p>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
                <li>
                  <Link to={"/travel-agency"}>Contact</Link>
                </li>
                <li>
                  <Link to={"/service"}>Services</Link>
                </li>
                <li>
                  <Link to={"/package"}>Packages</Link>
                </li>
                <li>
                  <Link to={"/travel-agency"}>Enquire</Link>
                </li>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mb-5">
              <div className="p-list">
                <p className="mini-heading">Package Category</p>
                <li>
                  <Link to="/international-tour-packages">International</Link>
                </li>
                <li>
                  <Link to="/domestic-tour-packages">Domestic</Link>
                </li>
                <li>
                  <Link to="/package?sub=1">Educational</Link>
                </li>
                <li>
                  <Link to="/package?sub=2">Honeymoon</Link>
                </li>
                <li>
                  <Link to="/package?sub=3">Cruises</Link>
                </li>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <div className="pp-list">
                <p className="mini-heading">Trending Packages</p>
                <li>
                  <Link to="/package?country=japan">Japan Tour Packages</Link>
                </li>
                <li>
                  <Link to="/package?country=indonesia">
                    Bali Tour Packages
                  </Link>
                </li>
                <li>
                  <Link to="/package?country=munnar">Munnar Tour Packages</Link>
                </li>
                <li>
                  <Link to="/package?country=thailand">
                    Thailand Tour Packages
                  </Link>
                </li>
                <li>
                  <Link to="/package?country=america">
                    America Tour Packages
                  </Link>
                </li>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mb-5">
              <div className="pp-list">
                <p className="mini-heading">Affordable Services</p>
                <li>
                  <Link to={"/service/ticket"}>Ticketing</Link>
                </li>
                <li>
                  <Link to={"/service/passport"}>Passport</Link>
                </li>
                <li>
                  <Link to={"/service/visa"}>VISA</Link>
                </li>
                <li>
                  <Link to={"/service/insurance"}>Travel Insurance</Link>
                </li>
                <li>
                  <Link to={"/service/currency"}>Currency Exchange</Link>
                </li>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <div className="ql-list">
                <p className="mini-heading">Legal Links</p>
                <li>
                  <Link to={"/privacypolicy"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/termspage"}>Terms and Conditions</Link>
                </li>
                {/* <li><Link to={"/delivery"}>Delivery and Shipping Policy</Link></li> */}
                <li>
                  <Link to={"/refund"}>Refund and Cancellation Policy</Link>
                </li>
                <li>
                  <Link to={"/quality"}>Quality Policy</Link>
                </li>
              </div>
            </div>
          </div>
          {/* <div className="seo-keywords">
              <p className="Keywordstopsearch">TOP SEARCH KEYWORDS</p>

      <p className="text-light-color">
        {sentencesWithLinks.map((item, index) => (
          <React.Fragment key={index}>
            <a href={item.link} style={linkStyle}>
              {item.sentence}
            </a>
            {index < sentencesWithLinks.length - 1 && <>&nbsp;|&nbsp;</>}
          </React.Fragment>
        ))}
      </p>
    </div> */}
          <div className="seo-keywords">
            <p className="Keywordstopsearch">TOP SEARCH KEYWORDS</p>
            <div className="row">
              {[...Array(3)].map((_, rowIndex) => (
                <div className="col-md-4" key={rowIndex}>
                  <ul>
                    {sentencesWithLinks
                      .slice(rowIndex * 6, rowIndex * 6 + 6)
                      .map((item, index) => (
                        <li key={index}>
                          <RiCheckDoubleFill className="tick-icon" />
                          &nbsp;&nbsp;&nbsp;
                          <Link to={item.link} style={linkStyle}>
                            {item.sentence}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="topbar">
        <a href="#top">
          <i className="fa-solid fa-circle-arrow-up"></i>
        </a>
      </div>

      <div className="infy-sec">
        <div className="infy-box">
          <a
            href="https://infygain.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Developed & Maintained By</p>
            <img
              src="https://infygain.com/images/others/logo-alt.svg"
              alt="Infygain Logo"
              title="Infygain Logo"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
