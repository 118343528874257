import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import axios from "axios";

import CommonHelmet from "./Components/CommonHelmet";

import { Link } from "react-router-dom";
import config from "./config";

const India = () => {
  const [asia, setAsia] = useState([]);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(config.apiUrl + "domestic.php");
        setAsia(res.data.country);

        const res1 = await axios.get(config.apiUrl + "statedata.php");
        setCountryData(res1.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <CommonHelmet
        pageTitle="Domestic Tour Packages from Coimbatore|Domestic Tour Packages from Chennai"
        pageDescription="Domestic Tour Packages from Coimbatore & Chennai at best price. Aspire Holidays offers Domestic Holiday Tour Packages.
      "
        pageKeywords="Best International Tour Packages from Coimbatore, Best Domestic Tour Package from
      Coimbatore, Thailand Package from Coimbatore, Maldives Package from Coimbatore, Singapore
      Package from Coimbatore, Malaysia Package from Coimbatore, Dubai Package from Coimbatore, Best
      Honeymoon Tours from Coimbatore
      "
      />
      <Header />
      <section className="cont-banner mb-5">
        <div className="banner-box">
          <h1 className="main-heading">DOMESTIC TOUR PACKAGES</h1>
          <p className="mini-heading">Explore Now</p>
        </div>
      </section>

      <div className="cnt-sec">
        <div className="container">
          <div className="row">
            {/* <?php
            if (count($country) == 0) {
                echo '<h3 class="text-center text-warning">No packages found</h3>';
            }
            foreach ($country as $row): ?> */}
            {!asia ? (
              <h3 className="text-center text-warning">No packages found</h3>
            ) : (
              <>
                {asia.map((asia) => (
                  <div className="col-md-6 col-lg-3 p-3 country-col">
                    {countryData.map(
                      (country) =>
                        country.value ===
                          asia.state.replace(/\s/g, "").toLowerCase() && (
                          <>
                            <Link to={`/package?sub=6&cat=2&country=${country.value}`}>
                              <div className="cnt-card ">
                                <img
                                  className="country-img"
                                  alt="country"
                                  title="country"
                                  src={`/asset/images/state/${asia.state}.webp`}
                                />
                                <div className="card-namee">
                                  <h2 className="mini-heading">
                                    {/* {asia.country} */}
                                    {country.name} Tour Packages
                                  </h2>
                                </div>
                              </div>
                            </Link>
                          </>
                        )
                    )}
                  </div>
                ))}
              </>
            )}
            {/* <?php endforeach; ?> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default India;
